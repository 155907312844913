<script setup lang="ts">
const title = ref('404 - Page Not Found')
const description = ref('The page you are looking for has moved or does not exist.')

// Fetch the branches data
const sanity = useSanity()
const query = groq`*[_type == "branches"] | order(orderRank asc)`
const { data: branches, error } = await useAsyncData('branches', () => sanity.fetch<Sanity.Default.Schema.Branches[]>(query))

if (error.value || error.value) {
  console.error(error)
  throw createError({ status: 404, message: 'Page Not Found' })
}
</script>

<template>
  <NuxtLayout>
    <Seo
      :title="title"
      :description="description"
    />
    <HeaderHeroSimple
      :headline="title"
      :subtitle="description"
    />

    <div class="bg-stone-100 mb-12">
      <div class="container-8xl pt-6 pb-12">
        <h3 class="thin-title">
          Offices
        </h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 mt-4 mb-12 gap-8">
          <div
            v-for="branch in branches"
            :key="branch?._id"
          >
            <!-- Branch Teams -->
            <UiFader class="h-full">
              <div
                class="wrap h-full"
              >
                <BranchQuickDetails
                  v-if="branch"
                  :branch="branch"
                  class="max-w-2xl h-full"
                />
              </div>
            </UiFader>
          </div>
        </div>
      </div>
    </div>

    <LazyCarouselsExpensiveProperties class="mb-16" />
  </NuxtLayout>
</template>

<style>
</style>
