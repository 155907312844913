<script lang="ts" setup>
const props = defineProps({
  dark: {
    type: Boolean,
    default: false,
    required: false,
  },
  hidden: {
    type: Boolean,
    default: false,
    required: false,
  },
  title: {
    type: String,
    default: '',
    required: false,
  },
})
const route = useRoute()

const convertToHumanFriendlyTitle = (path: string) => {
  // Implement basic logic to convert specific paths into human-friendly titles
  if (path === 'estate-agent') {
    return 'Estate Agents'
  }
  else {
    // Default conversion: capitalize the first letter and replace dashes with spaces
    return path.charAt(0).toUpperCase() + path.slice(1).replace(/-/g, ' ')
  }
}

const trimStringToLength = (str: string, length: number) => {
  if (str.length <= length) {
    return str
  }

  let trimmedString = str.substring(0, length)
  // Trim to the last space character
  trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))

  return `${trimmedString}...`
}

const breadcrumbs = computed(() => {
  const paths = route.fullPath.split('/').filter(Boolean)

  const result = [{
    item: '/',
    name: 'Home',
  }]

  for (let i = 0; i < paths.length; i++) {
    const path = paths[i]

    // if this is the last path, preferentially add the title prop
    if (i === paths.length - 1 && props.title) {
      result.push({
        item: `/${paths.slice(0, i + 1).join('/')}`,
        name: props.title || trimStringToLength(convertToHumanFriendlyTitle(path), 25),
      })
      break
    }

    result.push({
      item: `/${paths.slice(0, i + 1).join('/')}`,
      name: trimStringToLength(convertToHumanFriendlyTitle(path), 25),
    })
  }

  return result
})

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: breadcrumbs,
  }),
])
</script>

<template>
  <div
    class="flex gap-4 items-center mb-3"
    :class="hidden ? 'sr-only' : ''"
  >
    <div class="inline-flex items-center text-xs font-semibold">
      <nav aria-label="breadcrumb" class="">
        <ol
          class="flex items-center space-x-2 text-xs"
          :class="!dark ? 'text-gray-700' : 'text-gray-100'"
        >
          <template v-for="(crumb, index) in breadcrumbs" :key="index">
            <li v-if="index < breadcrumbs.length - 1">
              <nuxt-link
                :to="crumb.item"
                :class="!dark ? 'text-forest-600 hover:text-forest-400' : 'text-gray-100 hover:text-gray-400'"
              >
                {{ crumb.name }}
              </nuxt-link>
            </li>
            <Icon v-if="index < breadcrumbs.length - 1" name="carbon:chevron-right" class="mx-1 text-forest-300" />
            <li v-else class="opacity-70 capitalize">
              {{ crumb.name }}
            </li>
          </template>
        </ol>
      </nav>
    </div>
  </div>
</template>

<style scoped>
/* Add your custom styles here if needed */
</style>
