<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: false,
  },
  canonical: {
    type: String,
    required: false,
  },
})

const route = useRoute()
const defaultImageUrl = 'https://www.butlersherborn.co.uk/img/site.png'

// if the route is the home page, override the title
const myTitle = route.path === '/' ? 'Butler Sherborn | Cotswolds Property Specialist' : `${props.title} | Butler Sherborn`

const headObject = {
  title: myTitle,
  meta: [
    {
      name: 'description',
      content: props.description,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: myTitle,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: props.description,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: props.imageUrl ?? defaultImageUrl,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: route.fullPath,
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: myTitle,
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: props.description,
    },
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image',
    },
  ],
} as any

if (props.canonical) {
  headObject.link = [{
    rel: 'canonical',
    href: props.canonical,
  }]
}

if (props.imageUrl) {
  headObject.meta.push({
    hid: 'og:image',
    property: 'og:image',
    content: props.imageUrl,
  })
  headObject.meta.push({
    hid: 'twitter:image',
    name: 'twitter:image',
    content: props.imageUrl,
  })
}
else {
  headObject.meta.push({
    hid: 'og:image',
    property: 'og:image',
    content: defaultImageUrl,
  })
  headObject.meta.push({
    hid: 'twitter:image',
    name: 'twitter:image',
    content: defaultImageUrl,
  })
}

useHead(headObject)
</script>

<template>
  <div class="meta hidden">
    {{ title }}
  </div>
</template>
