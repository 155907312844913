export default defineAppConfig({
  ui: {
    primary: 'forest',
    gray: 'cool', // aka normal gray
    button: {
      primary: 'forest',
      secondary: 'birch',
      rounded: 'rounded-full',
      size: {
        xs: 'text-sm',
        sm: 'text-md',
        md: 'text-lg',
        lg: 'text-lg',
        xl: 'text-xl',
      },
      padding: {
        xs: 'py-1.5 px-2.5',
        sm: 'py-1.5 px-3',
        md: 'py-2 px-3.5',
        lg: 'py-2.5 px-4',
        xl: 'py-2.5 px-5',
      },
    },
  },
})
