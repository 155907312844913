<script setup lang="ts">

</script>

<template>
  <NuxtLoadingIndicator
    color="#99b867"
  />
  <NuxtLayout class="min-h-full font-manrope font-sans antialised text-gray-700">
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
  .max-w-8xl {
    max-width: 88rem;
  }

  .max-w-9xl {
    max-width: 96rem;
  }

  .max-w-10xl {
    max-width: 104rem;
  }

  /* Transitions */
  .page-enter-active,
  .page-leave-active {
    transition: all 0.3s;
  }
  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    filter: blur(1rem);
  }

  /* DJ's Styles */
  .container-8xl {
    @apply max-w-10xl mx-auto px-4 lg:px-8;
  }

  .eyebrow  {
    @apply text-xs lg:text-sm lg:font-bold text-forest-400 mb-2;
  }
  .eyebrow.dark {
    @apply text-xs lg:text-sm lg:font-bold text-forest-600 mb-2;
  }
  .eyebrow.light {
    @apply text-xs lg:text-sm lg:font-bold text-forest-50 mb-2;
  }

  .section-title {
    @apply text-lg text-gray-900 font-semibold mb-0;
  }

  .hero-image {
    .eyebrow  {
      @apply text-sm lg:text-base lg:font-bold text-forest-100 mb-3;
    }
    .headline {
      @apply text-3xl lg:text-5xl xl:text-7xl text-white font-semibold;
    }
    .subtitle {
      @apply text-sm md:text-base lg:text-2xl text-gray-50 font-normal;
    }
  }

  img {
    filter: saturate(102%) contrast(102%) brightness(98%);
  }

  /* Typography */
  p,h1,h2,h3,h4,h5,h6 {
    @apply mb-3;
  }
  .break-word {
    word-break: break-word;
  }
  h1 {
    @apply font-semibold tracking-tight text-gray-900;
  }
  label p {
    @apply mb-0;
  }
  h2 {
    @apply font-semibold text-3xl text-gray-900;
  }
  h3 {
    @apply font-semibold text-2xl text-gray-900;
  }
  h4 {
    @apply font-semibold text-xl text-gray-900;
  }
  h5 {
    @apply text-lg text-gray-900;
  }
  h6 {
    @apply text-base;
  }

  .thin-title {
    @apply text-xl lg:text-3xl font-normal;
  }
  .thin-subtitle {
    @apply text-sm lg:text-3xl font-normal;
  }
  .thin-title-sm {
    @apply text-lg lg:text-xl font-normal;
  }

  // carousel
  @media (min-width: 1664px) {
    .magic-padding:first-child {
      padding-left: calc(((100% - 104rem) / 2) + 2rem);
    }
  }
  @media (min-width: 1024px) and (max-width: 1664px) {
    .magic-padding:first-child {
      padding-left: 2rem;
    }
  }
  @media (max-width: 1024px) {
    .magic-padding:first-child {
      padding-left: 0;
    }
  }

  .wrap {
  @apply md:pl-8 md:relative
    before:md:absolute before:md:bg-forest-300 before:md:left-0 before:md:top-0 before:md:h-8 before:md:w-px
    after:md:absolute after:md:bg-gray-950/10 after:md:bottom-0 after:md:left-0 after:md:top-10 after:md:w-px;
  }

  /* Sanity Content  */
  .sanity-content {
    @apply text-gray-700;
    white-space: pre-line;

    a {
      @apply text-forest-600 hover:text-forest-500;
    }
    h2, h3 {
      @apply mt-6 mb-3;
    }
    ol {
      @apply list-decimal list-outside ml-6 mb-3;
    }
    ul {
      @apply list-disc list-outside ml-6 mb-3;
    }
    ol li,
    ul li {
      @apply mb-2;
    }
  }

  /* Formkit Overrides */
  ul.formkit-options {
    @apply list-none ml-0 mb-0;
  }

  /* Scroll Overrides */
  .no-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  .honey {
    @apply hidden;
  }

  /* Menu Nav */
  .nav-hero {
    &.is-fixed {
      @apply translate-y-0 bg-transparent text-white;
    }
    &.is-sticky {
      @apply translate-y-0 bg-white text-gray-700 border-stone-200 border-opacity-70 border-b;
    }
  }

  .nav-no-hero {
    &.is-fixed {
      @apply translate-y-0 bg-white text-gray-700;
    }

    &.is-sticky {
      @apply translate-y-0 bg-white text-gray-700 border-stone-200 border-opacity-70 border-b;
    }
  }

  .nav-hero {
    .logo {
      @apply brightness-0 invert;

      &.logo-is-fixed {
        @apply brightness-0 invert;
      }

      &.logo-is-sticky {
        @apply brightness-100 invert-0;
      }
    }
  }
</style>
