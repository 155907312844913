const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000"
const _white = "#fff"
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _amber = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _forest = {"50":"#f3f6f3","100":"#e3eae1","200":"#c6d5c5","300":"#9fb79e","400":"#729572","500":"#527653","600":"#3e5d3f","700":"#324a34","800":"#293c2b","900":"#223224","950":"#131b14","DEFAULT":"#3e5d3f"}
const _birch = {"50":"#EDF2E4","100":"#E4ECD6","200":"#D1DFBA","300":"#BED29F","400":"#ACC583","500":"#99B867","600":"#7B9A48","700":"#597034","800":"#384621","900":"#171D0D","950":"#060804","DEFAULT":"#99B867"}
const _stone = {"50":"#FEFEFD","100":"#FCFBF8","200":"#F8F5ED","300":"#F4EFE2","400":"#EFE9D7","500":"#EBE3CC","600":"#E4D9BA","700":"#DDCFA7","800":"#D5C595","900":"#CEBB83","950":"#CBB67A","DEFAULT":"#EBE3CC"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "current": _current, "transparent": _transparent, "black": _black, "white": _white, "gray": _gray, "red": _red, "amber": _amber, "forest": _forest, "birch": _birch, "stone": _stone, "primary": _primary, "cool": _cool,  }
export { config as default, _current, _transparent, _black, _white, _gray, _red, _amber, _forest, _birch, _stone, _primary, _cool }