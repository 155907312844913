import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'auth',
  persist: {
    storage: persistedState.localStorage,
  },

  state: () => ({
    secret: '',
    user: null,
  }),

  actions: {
    async signIn(user: any) {
      // give me a random 8 character string:
      const password = 'CotswoldsGL7'

      // check if the password is correct
      if (user.password !== password) {
        throw new Error('Invalid password')
      }

      this.user = user
      this.secret = 'hiof-327h-sciq-3r9f'
    },
  },
})
