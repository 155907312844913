import { useAuthStore } from '~/stores/auth'

// global middleware - all routes
export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) {
    return
  }

  // only apply this middleware in development/staging/preview
  const runtimeConfig = useRuntimeConfig()
  if (runtimeConfig.public.env === 'production') {
    return
  }

  // return if we are navigating from the login page
  if (from.fullPath === '/login') {
    return
  }

  // // console.log('middleware/auth.global.ts');
  const authStore = useAuthStore()
  const { secret } = storeToRefs(authStore)

  const siteSecret = 'hiof-327h-sciq-3r9f'

  if ((!secret || (secret.value !== siteSecret)) && to.fullPath !== '/login') {
    // console.log('navigate to login - not authenticated')
    return navigateTo('/login')
  }
})
