<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core'

const target = ref<HTMLElement | null>(null)
const targetIsVisible = useElementVisibility(target, { threshold: 0.5 })
const fadeInNow: Ref<boolean> = ref(false)

const unwatch = watch(targetIsVisible, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    fadeInNow.value = true
    // now unwatch the target
    unwatch()
  }
})
</script>

<template>
  <div
    ref="target"
    class="fader transition-opacity duration-500 ease-in"
    :class="fadeInNow ? 'opacity-100' : 'opacity-0'"
  >
    <slot />
  </div>
</template>
