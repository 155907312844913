<script setup lang="ts">
const props = defineProps({
  size: {
    type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
    default: 'lg',
  },
  color: {
    type: String as PropType<'forest' | 'birch' | 'stone' | 'black' | 'white' | 'gray' | 'transparent'>,
    default: 'forest',
  },
  variant: {
    type: String as PropType<'solid' | 'outline' | 'ghost' | 'link' | 'soft'>,
    default: 'solid',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  block: {
    type: Boolean,
    default: false,
  },
  to: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  padded: {
    type: Boolean,
    default: true,
  },
  showArrow: {
    type: Boolean,
    default: true,
  },
  clickThrough: {
    type: Function,
    default: null,
  },
})

const handleClick = () => {
  if (props.clickThrough) {
    props.clickThrough()
  }
}
</script>

<template>
  <UButton
    :size="size"
    :color="color"
    :variant="variant"
    :loading="loading"
    :block="block"
    :padded="padded"
    :to="to"
    class="inline-flex items-center gap-2 group"
    @click="handleClick"
  >
    <slot />
    <span v-if="label">{{ label }}</span>
    <Icon
      v-if="showArrow"
      name="ic:sharp-arrow-forward"
      class="-mt-1 group-hover:translate-x-1 transition-transform duration-300 ease-in-out shrink-0"
      aria-hidden="true"
    />
  </UButton>
</template>
